import React, { useState } from 'react';
import { cannonStyled, NSH3, TWH1, useCannonStyletron } from '../../../theme';
import PaypalSuccess from '../../../assets/payment-completed-card.svg';
import PayPalIcon from '../../../assets/paypal-icon.svg';
import { useOrientation, useTableOrMobile } from '../../../hooks';
import PayPal from '../../../atoms/PayPal';
import { Button, CustomInput } from '../../../atoms';

const ButtonContainer = cannonStyled('div', {
  paddingTop: '30px',
  width: '100%',
});

const MainContainer = cannonStyled(
  'div',
  ({ isRotated }: { isRotated: boolean }) => ({
    padding: isRotated ? '50px 0px 50px 0px' : '',
    width: '80%',
  })
);

interface ICombinationPaypal {
  title: string;
  subTitle: string;
}

const ReplacementPartsPaypal = (props: ICombinationPaypal) => {
  // HOOKS

  //STATE
  const [css, theme] = useCannonStyletron();
  const [isMobile, isTablet] = useTableOrMobile();
  const [amount, setAmount] = useState(0);
  const [showPaypal, setShowPaypal] = useState(false);
  const currency = 'USD';
  
  // RENDER
  const { isRotated } = useOrientation();
  return (
    <MainContainer isRotated={isRotated}>
      <ButtonContainer>
        {showPaypal ? (
          <PayPal
            amount={amount}
            currency={'USD'}
            items={[]}
            successfulImage={PaypalSuccess}
            description={'Replacement parts'}
            successfulText={
              'We have sent your replacement parts confirmation to your mail. Be sure emails from cannonsecurityproducts.com and cannonsafe.com are on your safe sender list.'
            }
            successfulTitle={'Your payment was completed successfully'}
            onPaymentApproved={(value) => {
              // handleSendData(value);
              // setPaymentDone(true);
            }}
          />
        ) : (
          <>
            <PayPalIcon />
            <NSH3
              color={theme.cannonColors?.primaryBlue}
              weight={900}
              align={isMobile ? 'left' : 'center'}
              margin={20}
            >
              {props.title}
            </NSH3>
            <TWH1
              color={theme.cannonColors?.primaryGray}
              weight={400}
              align={isMobile ? 'left' : 'center'}
              style={{ fontSize: `min(16px, 18px)` }}
              margin={5}
            >
              {props.subTitle}
            </TWH1>
            <CustomInput
              type='number'
              noMarginLeft
              errorMessage='Field Required'
              error={showPaypal}
              positive={amount !== 0}
              label='Total payment amount'
              placeholder='100'
              value={amount}
              onChange={(e: any) => setAmount(e.currentTarget.value)}
              required
              requiredStyle
            />
            <Button.RoundedButton
              onClick={() => {
                amount <= 0 ? setShowPaypal(false) : setShowPaypal(true);
              }}
              size='compact'
              color='primary'
              $style={{
                paddingTop: '8px',
                paddingBottom: '8px',
                paddingLeft: '12px',
                paddingRight: '12px',
                width: '100%',
                marginTop: '30px',
              }}
            >
              Checkout
            </Button.RoundedButton>
          </>
        )}
      </ButtonContainer>
    </MainContainer>
  );
};

export default ReplacementPartsPaypal;
