import React, { useState } from 'react';
import { PageProps } from 'gatsby';
import { graphql, useStaticQuery } from 'gatsby';
import { cannonStyled, NSH3, TWH1, useCannonStyletron } from '../theme';
import { useTableOrMobile } from '../hooks';
import { LayoutProvider, Wrapper } from '../components/layout';
import { CannonBreadcrumbs } from '../atoms';
import { ResponsiveContainer } from '../atoms/Container';
import { PaypalPayment } from '../components/sections';
import ReplacementPartsPaypal from '../components/sections/PaypalPayment/ReplacementPartsPaypal';

const BreadCrumbsContainer = cannonStyled(
  'div',
  (props: { isMobile: boolean }) => ({
    position: 'absolute',
    top: props.isMobile ? '10px' : '30px',
    left: props.isMobile ? '10px' : '75px',
    width: '100%',
    textAlign: 'left',
  })
);

const MainWrapper = cannonStyled(
  Wrapper,
  (props: { isMobile: boolean; isTablet?: boolean }) => ({
    display: 'flex',
    maxWidth: '1280px',
    width: props.isMobile ? '95%' : props.isTablet ? '90%' : '100%',
    justifyContent: 'center',
    padding: props.isMobile ? '100px 10px' : '50px 10px',
    marginLeft: 'auto',
    marginRight: 'auto',
    overflowX: 'hidden',
    textAlign: 'center',
  })
);

export const KeysReplacementPayment: React.FC<PageProps> = (
  props: PageProps
) => {
  const [isMobile, isTablet] = useTableOrMobile();
  const [showPaypal, setShowPaypal] = useState(false);

  return (
    <LayoutProvider seo locationSearch={props.location.search}>
      <MainWrapper isMobile={isMobile} isTablet={isTablet} noScroll id='main'>
        <BreadCrumbsContainer isMobile={isMobile}>
          <CannonBreadcrumbs
            pages={[
              { title: 'Home', href: '/' },
              { title: 'Support', href: '' },
              {
                title: 'Replacement Parts Request',
                href: '/replacement-parts',
              },
              { title: 'Payment', href: '/replacement-parts-payment' },
            ]}
          />
        </BreadCrumbsContainer>
        <ResponsiveContainer>
          {showPaypal ? (
            <ReplacementPartsPaypal
              title={'Replacement parts request'}
              subTitle={
                'You requested a replacement part for your Cannon Safe. Our Customer Service team has set up a secure PayPal page in order to purchase your part. Please enter the total value of the items that the agent confirmed are available to complete the purchase'
              }
            />
          ) : (
            <PaypalPayment secondStep={() => setShowPaypal(true)} />
          )}
        </ResponsiveContainer>
      </MainWrapper>
    </LayoutProvider>
  );
};

export default KeysReplacementPayment;
