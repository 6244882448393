import React, { useEffect, useRef, useState } from 'react';
import { NSH1, useCannonStyletron } from '../../theme';
import { IFileImage } from '../../../src/@types';
import Success from './success';
import usePaypalID from '../../hooks/usePaypalID';
import { Spinner } from 'baseui/spinner';

interface Item {
  name: string;
  quantity: string;
  unit_amount: { value: string; currency_code: string };
  sku?: string;
}

interface Props {
  amount: number;
  currency: string;
  items: Item[];
  successfulImage: IFileImage;
  successfulText: string;
  successfulTitle: string;
  description: string;
  onPaymentApproved: (data: any) => void;
}

const PayPal = ({
  amount = 0,
  currency = 'USD',
  items = [] as Item[],
  successfulImage = {} as IFileImage,
  successfulText = '',
  successfulTitle = '',
  description = '',
  onPaymentApproved,
}: Props) => {
  // HOOKS
  const paypal = useRef() as any;
  const paypalElement = document.getElementById('paypalElement');
  const paypalClientID = usePaypalID();
  const [css, theme] = useCannonStyletron();
  // STATE

  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [processFinished, setProcessFinished] = useState(false);
  const [success, setSuccess] = useState(false);

  // EFFECTS
  useEffect(() => {
    let script = document.createElement('script');
    script.defer = true;
    script.src = `https://www.paypal.com/sdk/js?client-id=${paypalClientID}&currency=${currency}`;
    script.onload = () => {
      setScriptLoaded(true);
    };
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    }
  }, [amount, currency]);

  useEffect(() => {
    if (scriptLoaded === true) {
      const _window = window as any;
      _window.paypal
        .Buttons({
          createOrder: function (data, actions) {
            return actions.order.create({
              purchase_units: [
                {
                  description: description,
                  amount: {
                    currency_code: currency,
                    value: amount,
                    breakdown: {
                      item_total: {
                        /* Required when including the `items` array */
                        currency_code: currency,
                        value: amount,
                      },
                    },
                  },
                  items,
                },
              ],
            });
          },
          onApprove: async (data: any, actions) => {
            const transactionId = await actions.order
              .capture()
              .then(function (details: any) {
                var transid = details.purchase_units[0].payments.captures[0].id;
                return transid;
              });
            setProcessFinished(true);
            setSuccess(true);
            data.transactionID = transactionId;
            onPaymentApproved(data);
          },
          onError: (err: any) => {
            setProcessFinished(true);
            setSuccess(false);
          },
        })
        .render(paypal.current);
    }
  }, [scriptLoaded, amount, currency]);

  // HANDLERS && FUNCTIONS

  // RENDERS
  return (
    <div>
      {success && processFinished ? (
        <Success
          image={successfulImage}
          title={successfulTitle}
          text={successfulText}
        />
      ) : (
        <div>
          {scriptLoaded ? (
            <div>
              <NSH1
                $style={{ marginBottom: '10px', fontSize: '24px' }}
                weight={900}
                color={theme.cannonColors.primaryBlue}
                align='center'
              >
                Your total payment:
              </NSH1>
              <NSH1
                $style={{ marginBottom: '12vh' }}
                weight={900}
                color={theme.cannonColors.primaryBlue}
                align='center'
              >
                $ {amount}
              </NSH1>
            </div>
          ) : (
            <Spinner />
          )}
          <div ref={paypal} id='paypalElement'></div>
        </div>
      )}
    </div>
  );
};

export default PayPal;
