import React from 'react';
import { Button } from '../';
import { IFileImage } from '../../@types';
import { useAPIURL, useTableOrMobile } from '../../hooks';
import { cannonStyled, NSH3, TWParagraph3, useCannonStyletron } from '../../theme';
import PaypalSuccess from '../../assets/payment-completed-card.svg';

const MainContainer = cannonStyled('div', {
  paddingBottom: '50px',
});
const Image = cannonStyled('img', {
  width: '150px',
  height: 'auto',
  marginBottom: '20px',
});

const FlexContainer = cannonStyled('div', {
  display: 'flex',
  flex: '1',
  justifyContent: 'center',
  alignItems: 'center',
});

const ButtonContainer = cannonStyled('div', {
  margin: '30px 10px 20px 10px',
});

interface Props {
  image: IFileImage;
  text: string;
  title: string;
}

const Success = ({ image = {} as IFileImage, text = '', title=''}: Props) => {
  const [css, theme] = useCannonStyletron();
  const API_URL = useAPIURL();
  const [isMobile ] = useTableOrMobile();
  
  return (
    <MainContainer>
      {image.url === undefined ? <PaypalSuccess/> : <Image src={API_URL + image.url} />}
      <NSH3
        weight='bold'
        align='center'
        $style={{ marginBottom: '20px', fontSize: isMobile ? '36px' : '44px' }}
        color={theme.cannonColors?.primaryBlue}
      >
        {title}
      </NSH3>
      <TWParagraph3
        color={theme.cannonColors.secondarySilverDark}
        weight={400}
        align='center'
        style={{ fontSize: '20px' }}
      >
        {text}
      </TWParagraph3>
      <FlexContainer>
        <ButtonContainer>
          <Button.RoundedButton
            $style={{ height: '40px', borderRadius: '8px', padding: '20px'}}
            onClick={() => {
              window.location.href = '/faq';
            }}
            size='mini'
            color='primary'
          >
            Any questions? Go to FAQ’s
          </Button.RoundedButton>
        </ButtonContainer>
      </FlexContainer>
    </MainContainer>
  );
};
export default Success;
